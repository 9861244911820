import React from 'react';
import { graphql } from 'gatsby';

import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import Layout from '../../../components/atoms/layout';
import PageTitle from '../../../components/PageTitle';
import { Question } from '../../../template/legal/styles';
import TermOfUseProps from '../../../interface/template';
import { Text2, Text3 } from '../../../components/atoms/body';
import withI18next from '../../../i18n/TemplateI18n';

const TermOfUse: React.SFC<any> = (props: TermOfUseProps) => {
  const { pageContext } = props;
  const { pageName } = pageContext;

  return (
    <>
      <Header pageName={pageName} showBackground>
        <PageTitle subtitle="" title="Term Of Use" />
      </Header>
      <Layout>
        <Question>
          <Text3 as="p">Bienvenue sur le site web d’Ironhack </Text3>
          <Text3 as="p">
            Bienvenue sur le site d'Ironhack, et merci de votre visite. Nous
            espérons que vous apprécierez cette expérience.
          </Text3>
          <Text3 as="p">
            Ironhack, Inc. (« <strong>Ironhack</strong> », « 
            <strong>nous</strong> », « <strong>nos</strong> » ou « 
            <strong>notre</strong> ») est un fournisseur international de cours
            de développement web et de conception UX/UI disposant de campus dans
            différents endroits dans le monde. Ces conditions d'utilisation (« 
            <strong>conditions</strong> ») s'appliquent au site web d'Ironhack
            situé à l’adresse{' '}
            <a href="https://www.ironhack.com/fr">https://www.ironhack.com</a>,
            à ses centres partout dans le monde et à ses filiales et sociétés
            associées. Ces conditions constituent un contrat légal entre vous
            (« 
            <strong>vous</strong> », « <strong>votre</strong> ») et nous, et
            régissent l'utilisation de tous les textes, données, informations,
            logiciels, graphiques, photographies et autres éléments (ci-après,
            les « <strong>matériels »</strong>) que nous, nos filiales et nos
            sociétés associées pouvons mettre à votre disposition, ainsi que
            tous les services (« <strong>services</strong> ») que nous pouvons
            proposer sur notre site web (ci-après dénommés « 
            <strong>site</strong> » dans ces conditions).
          </Text3>
          <Text3 as="p">
            VEUILLEZ LIRE ATTENTIVEMENT CES CONDITIONS AVANT DE NAVIGUER SUR CE
            SITE WEB. L'UTILISATION DE CE SITE WEB INDIQUE QUE VOUS AVEZ LU ET
            ACCEPTÉ CES CONDITIONS. VOUS NE POUVEZ PAS UTILISER CE SITE WEB SI
            VOUS N'ACCEPTEZ PAS CES CONDITIONS. SI VOUS N'ÊTES PAS D'ACCORD AVEC
            CES CONDITIONS, VEUILLEZ NE PAS UTILISER CE SITE WEB.
          </Text3>
          <Text3 as="p">
            NOTE : CES CONDITIONS CONTIENNENT UNE DISPOSITION SUR LE RÈGLEMENT
            DES LITIGES ET L'ARBITRAGE ; ELLES COMPRENNENT UNE RENONCIATION À
            PARTICIPER À UN RECOURS COLLECTIF AFFECTANT VOS DROITS EN VERTU DES
            PRÉSENTES CONDITIONS À L’ÉGARD DE TOUT LITIGE SUSCEPTIBLE DE SE
            PRODUIRE ENTRE VOUS ET LA SOCIÉTÉ. TEL QU’INDIQUÉ CI-DESSOUS, VOUS
            POUVEZ RENONCER À L'ARBITRAGE EXÉCUTOIRE INDIVIDUEL ET VOUS
            SOUSTRAIRE À LA DISPOSITION DE RENONCIATION À PARTICIPER À UN
            RECOURS COLLECTIF.
          </Text3>
          <Text3 as="p">
            Ironhack se réserve le droit de changer, modifier, ajouter ou
            supprimer des parties de ces conditions, à sa seule discrétion et à
            tout moment. Veuillez consulter régulièrement ces conditions.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">CHANGEMENTS</Text2>
          <Text3 as="p">
            Nous pouvons, à notre seule discrétion, modifier les matériels et
            les services que nous proposons ou décider de modifier, suspendre ou
            interrompre ce site web à tout moment sans préavis. De temps à
            autre, nous pouvons également changer, mettre à jour, ajouter ou
            supprimer des dispositions (dans l’ensemble, les « modifications »)
            des présentes conditions. Dans un souci de transparence bénéfique à
            tous, nous nous engageons à vous informer de toute modification
            apportée aux présentes conditions via une publication sur ce site
            web.
          </Text3>
          <Text3 as="p">
            Si vous n'acceptez pas de telles modifications, votre seul recours
            est de cesser d'utiliser ce site web. En continuant d'utiliser ce
            site web après avoir été informé des modifications en question, vous
            reconnaissez, acceptez et exprimez votre accord avec le fait d’être
            lié par les conditions. Veuillez également noter que ces conditions
            peuvent être remplacées par des mentions ou conditions légales
            expressément désignées, indiquées sur certaines pages de ce site
            web. Ces mentions ou conditions expressément désignées seront
            incorporées aux présentes conditions et remplaceront les
            dispositions des présentes conditions désignées à cette fin.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">UTILISATION GÉNÉRALE</Text2>
          <Text3 as="p">
            En utilisant ce site web, vous confirmez avoir au moins 18 ans. Si
            vous avez moins de 18 ans, vous devez obtenir la permission d'un
            adulte pour utiliser ce site web et en accepter les conditions, et
            cet adulte doit être un parent ou un tuteur légal acceptant la
            responsabilité de l’utilisation de ce site de votre part.
          </Text3>
          <Text3 as="p">
            Nous vous invitons à utiliser ce site web à des fins personnelles
            (« <strong>fins autorisées</strong> »). Profitez bien de votre
            visite !
          </Text3>
          <Text3 as="p">
            Via ces conditions, nous vous accordons une licence limitée,
            personnelle, non exclusive et non transférable pour utiliser et
            visualiser les matériels. Votre droit d'utiliser les matériels est
            assujetti au respect des présentes conditions de votre part. Vous
            n'avez aucun autre droit sur ce site web ou sur aucun des matériels,
            et vous ne pouvez pas modifier, éditer, copier, reproduire, créer
            des œuvres dérivées, procéder à de l’ingénierie inverse, modifier,
            améliorer ou exploiter de quelque manière que ce soit quoi que ce
            soit de ce site web ou des matériels. Si vous faites des copies de
            l’une des parties de ce site web tout en l’utilisant à l'une des
            fins autorisées, nous vous conseillons de conserver des copies de
            tous nos droits d'auteur et autres droits de propriété tels qu'ils
            apparaissent sur ce site web.
          </Text3>
          <Text3 as="p">
            Si vous ne respectez pas l'une de ces conditions, la licence
            susmentionnée expirera automatiquement et vous devrez détruire
            immédiatement tous les documents que vous aurez téléchargés ou
            imprimés (ainsi que toutes les copies de ces derniers).
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">UTILISATION DE CE SITE WEB ET DE NOS SERVICES</Text2>
          <Text3 as="p">
            Nous vous remercions de votre visite, et vous ouvrons nos portes
            afin que vous puissiez consulter notre site sans engagement et sans
            avoir à vous inscrire.
          </Text3>
          <Text3 as="p">
            Cependant, pour avoir accès à certains services, à nos instructeurs
            ou aux matériels spécifiques d’un cours, vous devez d'abord vous
            inscrire puis être accepté dans l'un de nos Bootcamps de
            développement web ou de Design UX/UI ou dans l’un de nos programmes
            à temps partiel « part time » (« <strong>Bootcamp Ironhack</strong>
             »).
          </Text3>
          <Text3 as="p">
            Il peut exister des conditions générales supplémentaires applicables
            à certains services ou à certaines parties et fonctions spécifiques
            du site web. Au moment de vous inscrire pour bénéficier de nos
            services, vous devez accepter ces conditions.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">INSCRIPTION À IRONHACK</Text2>
          <Text3 as="p">
            Si vous souhaitez vous inscrire à un Bootcamp Ironhack, vous devez
            soumettre les informations suivantes après avoir cliqué sur le
            bouton « 
            <a href="https://www.ironhack.com/fr/cours/inscription">
              Apply now
            </a>
            &nbsp; » sur ce Site Web:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Prénom et nom;</li>
              <li>Numéro de téléphone;</li>
              <li>Une adresse électronique active;</li>
              <li>Genre;</li>
              <li>Référence; et</li>
              <li>
                Une brève description de la raison pour laquelle vous êtes
                intéressé par Ironhack.
              </li>
            </ul>
            Au cours du processus d'inscription, vous pouvez également fournir
            des informations supplémentaires facultatives afin que nous
            puissions vous offrir une expérience plus personnalisée lors de
            l'utilisation de ce Site Web, mais nous vous laissons le choix. Une
            fois que vous aurez soumis les informations demandées, nous
            déterminerons si nous acceptons ou non votre inscription. Si votre
            demande est approuvée, vous recevrez un courriel détaillant les
            étapes nécessaires pour compléter le processus d'inscription. Si
            vous ne soumettez pas les informations énumérées ci-dessus, nous ne
            pourrons pas traiter votre formulaire d'inscription ou vous fournir
            nos services de la manière la plus appropriée.
          </Text3>
          <Text3 as="p">
            Vous êtes responsable du respect de ces conditions lorsque vous
            accédez à ce Site Web. Vous êtes responsable de l'obtention et de la
            maintenance de l'équipement et des services techniques nécessaires
            pour accéder à ce site web et l'utiliser, ainsi que du paiement de
            tous les frais associés. Il est également de votre responsabilité de
            maintenir la confidentialité des informations que vous nous
            fournissez lors de votre inscription au Bootcamp Ironhack. Si vous
            croyez que vos informations personnelles ou la sécurité de ce Site
            Web ont été violées de quelque manière que ce soit, vous devez nous
            informer immédiatement. Si vous avez besoin de plus d'informations,
            veuillez consulter la page{' '}
            <a href="https://www.ironhack.com/fr/mentions-legale/politique-de-confidencialite">
              Politique de confidentialité
            </a>
            .
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">COMMUNICATIONS ÉLECTRONIQUES</Text2>
          <Text3 as="p">
            En utilisant ce site web, vous acceptez de recevoir des
            communications électroniques de notre part, y compris des
            notifications par e-mail. Ces communications électroniques peuvent
            comprendre des notifications sur les taxes et frais applicables, des
            renseignements sur les transactions et d'autres informations liées
            au site web ou à nos cours. Ces communications électroniques font
            partie de la relation entre vous et nous. Vous acceptez que tout
            accord, notification, révélation ou autre communication qui vous
            sera envoyé de notre part par voie électronique puisse répondre aux
            exigences légales en matière de communication, y compris lorsque ces
            communications sont effectuées par écrit.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">POLITIQUE DE CONFIDENTIALITÉ</Text2>
          <Text3 as="p">
            Nous respectons les informations que vous nous fournissez et tenons
            à nous assurer que vous connaissez précisément la manière dont nous
            les utilisons. Veuillez consulter notre « 
            <strong>Politique de confidentialité</strong> » où sont expliqués
            tous les détails. En utilisant ce site web, vous acceptez et
            reconnaissez que les transmissions via Internet ne sont jamais
            absolument sécurisées ou privées. Gardez toujours à l'esprit que
            toute information envoyée via ou au site web peut être interceptée
            par des tiers et lue, même lorsqu'elle est cryptée.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">LIENS VERS DES SITES DE TIERS</Text2>
          <Text3 as="p">
            Il se peut que vous souhaitiez nous suivre, vous connecter à nous
            via les réseaux sociaux ou accéder à d'autres pages web mentionnées
            sur notre blog ou notre site web. Nous considérons que les liens
            sont utiles et indiquons parfois sur notre site web des liens vers
            des sites web de tiers. Si vous utilisez ces liens, vous quitterez
            notre site web. Nous n'avons aucune obligation d'examiner les sites
            web de tiers auxquels vous pouvez accéder à partir de notre site
            web, nous ne contrôlons pas les sites web de tiers et nous ne sommes
            pas responsables des sites web de tiers (ni des produits, services
            ou contenus disponibles via ces derniers). Par conséquent, nous ne
            cautionnons ni ne représentons de quelque manière que ce soit de
            tels sites web de tiers, ni les informations, contenus, logiciels,
            produits, services ou matériels qu'ils contiennent ou les résultats
            qui pourraient être obtenus suite à leur utilisation. Si vous
            décidez d'accéder à l'un des sites web dont le lien est indiqué sur
            notre site web, vous le faites à vos propres risques et devrez
            respecter les politiques de confidentialité ainsi que les conditions
            générales de ces sites web de tiers.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">ENVOIS</Text2>
          <Text3 as="p">
            Certaines parties de ce site web (la fonction de commentaires du
            blog par exemple), peuvent vous permettre d’envoyer des avis, des
            informations, des données, des textes, des messages ou d'autres
            matériels (« <strong>envois d’utilisateur</strong> »). Vous acceptez
            d'être seul responsable de tous vos envois d’utilisateur et que de
            tels envois soient non-confidentiels et ne soient pas soumis à des
            droits de propriété. De plus, nous ne garantissons pas que vous
            soyez en mesure d’éditer ou de supprimer les envois d'utilisateur
            que vous aurez effectués.
          </Text3>
          <Text3 as="p">
            Au moment d’effectuer tout envoi d'utilisateur, vous confirmez que :
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Vous possédez tous les droits sur vos envois d’utilisateur (y
                compris, notamment, tous les droits de reproduction et de
                publication de vos envois d’utilisateur) ou, alternativement,
                vous avez acquis tous les droits nécessaires sur vos envois
                d’utilisateur qui vous permettent de nous accorder les droits
                sur vos envois d’utilisateur tel que décrit dans ces conditions
                ;
              </li>
              <li>
                Vous avez réglé et règlerez dans leur intégralité tous les
                droits de licence, droits de douane et autres obligations
                financières de toute nature, découlant de l'utilisation et de
                l'exploitation commerciale de vos envois d'utilisateur ;
              </li>
              <li>
                Vos envois d'utilisateur ne violent aucun droit d'auteur, marque
                déposée, brevet, secret commercial ou autre droit de propriété
                intellectuelle, droit à la vie privée ou tout autre droit légal
                ou moral de tiers ;
              </li>
              <li>
                Vous acceptez de votre plein gré de renoncer à tous les « droits
                moraux » que vous pouvez posséder sur vos envois d'utilisateur ;
              </li>
              <li>
                Vous n’avez pas connaissance que les informations comprises dans
                vos envois d'utilisateur sont fausses, inexactes ou trompeuses ;
              </li>
              <li>
                Vos envois d'utilisateur ne violent aucune loi (y compris, mais
                sans s'y limiter, celles régissant le contrôle des exportations,
                la protection des consommateurs, la concurrence déloyale, les
                politiques anti-discrimination ou la publicité mensongère) ;
              </li>
              <li>
                Vos envois d'utilisateur ne sont pas, et ne peuvent
                raisonnablement être considérés comme diffamatoires, injurieux,
                menaçants, tendancieux ou offensant sur le plan racial, ethnique
                ou religieux, constitutifs d’une menace ou d’une contrainte
                illégale envers aucun individu, association ou corporation,
                vulgaires, pornographiques, obscènes ou portant atteinte à la
                vie privée d’autres personnes ;
              </li>
              <li>
                Vous ne recevrez à aucun moment une compensation ou une
                contrepartie de tiers pour avoir effectué vos envois
                d'utilisateur ;
              </li>
              <li>
                Vos envois d'utilisateur n'incluent pas de matériels de sites
                web de tiers ni d'adresses postales, adresses électroniques,
                coordonnées ou numéros de téléphone de tiers (autres que les
                vôtres) ;
              </li>
              <li>
                Vos envois d'utilisateur ne contiennent pas de virus, vers,
                logiciels espions, logiciels publicitaires ou autres programmes
                ou fichiers potentiellement néfastes ;
              </li>
              <li>
                Vos envois d'utilisateur ne contiennent aucune information
                confidentielle, protégée par des droits de propriété ou
                personnelle ; et
              </li>
              <li>
                Vos envois d'utilisateur ne contiennent pas ou ne constituent
                pas des publicités non sollicitées ou non autorisées, du
                matériel promotionnel, des spams, des chaînes de lettres, des
                systèmes pyramidaux ou toute autre forme de sollicitation.
              </li>
            </ul>
            En effectuant un envoi d'utilisateur, vous nous accordez une licence
            irrévocable, perpétuelle, transférable, non exclusive, entièrement
            payée, internationale et sans frais (sous-licenciable à de multiples
            niveaux) :
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                D’utiliser, distribuer, reproduire, modifier, adapter, publier,
                traduire, exécuter publiquement et afficher publiquement vos
                envois d’utilisateur (ou toute modification de ces derniers),
                intégralement ou en partie, sous tout format ou via tout moyen
                connu actuellement ou développé à l’avenir ;
              </li>
              <li>
                D’utiliser (et de permettre à d'autres d'utiliser) vos envois
                d’utilisateur de quelque manière et à quelque fin que ce soit (y
                compris, mais sans s'y limiter, à des fins commerciales) que
                nous jugeons appropriées à notre seule discrétion (y compris,
                sans s’y limiter, l'incorporation de vos envois d’utilisateur ou
                toute modification de ces derniers, intégralement ou en partie,
                à toute technologie, produit ou service) ;
              </li>
              <li>
                D’afficher des publicités relatives à vos envois d'utilisateur
                et d’utiliser vos envois d'utilisateur à des fins publicitaires
                et promotionnelles.
              </li>
            </ul>
            Nous ne tolérons pas les envois d’utilisateur préjudiciables ou
            offensants sur notre site web. Nous pouvons, bien que ce ne soit pas
            une obligation, présélectionner des envois d'utilisateur ou
            contrôler tout espace sur ce site web via lequel les envois
            d'utilisateur peuvent être effectués. Nous ne sommes tenus
            d'héberger, de publier ou de distribuer aucun envoi d'utilisateur
            sur ou via ce site web et nous pouvons supprimer à tout moment ou
            refuser tout envoi d'utilisateur pour quelque raison que ce soit.
            Nous ne sommes pas responsables des pertes, vols ou dommages de
            quelque nature que ce soit des envois d’utilisateur. Vous acceptez
            également que nous puissions librement transmettre vos envois
            d'utilisateur à des tiers sans aucune obligation de confidentialité
            de la part du destinataire.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">ACTIVITÉS NON AUTORISÉES</Text2>
          <Text3 as="p">
            Afin qu’il n’y ait aucun doute sur l’usage de ce site web, nous vous
            informons que vous êtes autorisé à l’utiliser uniquement aux fins
            autorisées. Toute autre utilisation de ce site en dehors des fins
            autorisées constituant une utilisation non autorisée de ce site est
            interdite. Il en est ainsi car en ce qui nous concerne l’un et
            l’autre, tous les droits de ce site web nous appartiennent.
          </Text3>
          <Text3 as="p">
            L'utilisation non autorisée de ce site web peut entraîner une
            violation des lois internationales et de celles de certains États
            des États-Unis d'Amérique sur la propriété intellectuelle.
            Souhaitant que cette relation soit la plus saine possible, nous vous
            indiquons quelques exemples de choses à éviter. Par conséquent, à
            moins que vous ne disposiez d’une autorisation de notre part
            établissant le contraire, vous n'êtes pas autorisé à utiliser ce
            site web de l'une des façons suivantes (nous ne vous indiquons que
            des exemples, la liste n'est pas exhaustive, elle ne contient pas
            tout ce que vous n'êtes pas autorisé à faire) :
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Pour tout usage public ou commercial incluant l'utilisation de
                ce site web sur un autre site ou via un environnement
                informatique en réseau ;
              </li>
              <li>
                D'une manière qui modifie, affiche publiquement, exécute
                publiquement, reproduit ou diffuse quoi que ce soit de ce site
                web ;
              </li>
              <li>
                D'une manière violant toute loi, réglementation, règle, ordre,
                traité ou toute autre législation locale, étatique, nationale,
                étrangère ou internationale ;
              </li>
              <li>Pour harceler ou porter atteinte à une autre personne ;</li>
              <li>
                Pour usurper l'identité d'une personne ou d’un organisme ou pour
                dénaturer de quelque manière que ce soit votre affiliation avec
                une personne ou une entité ;
              </li>
              <li>
                Pour modifier ou interférer avec ce site web ou les serveurs ou
                réseaux y étant connectés
              </li>
              <li>
                Pour utiliser tout moyen d’extraction de données, robots ou
                méthodes similaires de collecte ou d'obtention de données
                relatives à ce site web ; ou
              </li>
              <li>
                Tenter d'obtenir un accès non autorisé à toute rubrique de ce
                site web ou à tout compte, système informatique ou réseau
                connecté à ce site web, que ce soit par piratage, extraction de
                mots de passe ou via tout autre moyen.
              </li>
            </ul>
            Vous acceptez d'engager des avocats pour notre défense si vous
            violez ces conditions dans le cas où la violation représenterait des
            problèmes pour nous. Vous acceptez également de supporter les coûts
            de tout dommage que nous pourrions avoir à payer suite à vos
            infractions. Vous êtes seul responsable des violations que vous
            pourriez commettre à l’égard des présentes conditions. Nous nous
            réservons le droit d'assumer la défense et le contrôle exclusifs de
            toute question assujettie sous quelque forme que ce soit à une
            indemnisation de votre part, et dans un tel cas, vous acceptez de
            coopérer avec notre défense contre une telle réclamation.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">DROITS DE PROPRIÉTÉ</Text2>
          <Text3 as="p">
            « Ironhack » est une marque déposée de notre propriété. Les autres
            marques, noms et logos figurant sur ce site web appartiennent à
            leurs propriétaires respectifs.
          </Text3>
          <Text3 as="p">
            Sauf indication contraire dans les présentes conditions, tous les
            matériels, et leur mise à disposition sur ce site web, sont
            exclusivement de notre propriété, Copyright © 2018. Tous les droits
            qui ne sont pas explicitement accordés dans les présentes sont
            réservés. À moins que la loi applicable ne l'exige, toute
            reproduction, distribution, modification, retransmission ou
            publication de tout matériel protégé par des droits de propriété
            intellectuelle est interdite sans le consentement écrit exprès de la
            personne à laquelle appartient le droit de propriété intellectuelle
            ou la licence.
          </Text3>
          <Text3 as="p">
            Avant d'utiliser ou de reproduire toute marque déposée, nom, logo ou
            contenu sous quelque forme que ce soit, veuillez nous contacter afin
            de nous demander notre autorisation. Une telle utilisation sans
            autorisation constitue une violation du droit international qui
            protège l'auteur ou le propriétaire de la marque déposée, du nom ou
            du logo ou de tout autre contenu et est passible de sanctions
            économiques ou pénales spécifiques.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">VIOLATION DES DROITS DE PROPRIÉTÉ</Text2>
          <Text3 as="p">
            Nous respectons les droits de propriété intellectuelle d'autrui et
            attendons de vous que vous fassiez de même. Par conséquent, notre
            politique consiste à supprimer tout envoi d'utilisateur violant les
            droits de propriété intellectuelle d'autrui, en suspendant l'accès à
            ce site web (ou à toute partie de celui-ci) à tout utilisateur
            faisant usage de ce site web en violant les droits de propriété
            intellectuelle d'autrui, ou en suspendant, lorsque les circonstances
            l'exigeraient, le Bootcamp Ironhack de tout utilisateur faisant
            usage de ce site web en violant des droits de propriété
            intellectuelle d'autrui. Conformément au titre 17 du Code des
            États-Unis, nous avons mis en place des procédures permettant de
            recevoir des notifications écrites concernant des réclamations pour
            atteinte aux droits de propriété intellectuelle et pour traiter ces
            réclamations dans le respect de cette loi. Si vous pensez que vos
            droits d'auteur ou autres droits de propriété intellectuelle ont été
            violés par un utilisateur de ce site web, veuillez envoyer une
            notification écrite à Ironhack :
          </Text3>
          <Text3 as="p">À l’attention de l’agent du DMCA d’Ironhack</Text3>
          <Text3 as="p">
            Adresse e-mail :&nbsp;
            <a href="mailto: legal@ironhack.com">legal@ironhack.com</a>
          </Text3>
          <Text3 as="p">
            Afin de garantir que la question soit traitée immédiatement, toute
            notification écrite doit :
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Contenir votre signature physique ou électronique ;</li>
              <li>
                Identifier l'œuvre protégée par des droits d'auteur ou toute
                autre propriété intellectuelle qui auraient été violés ;
              </li>
              <li>
                Identifier le matériel pour lequel des droits auraient été
                violés avec suffisamment de détails pour que nous puissions le
                localiser ;
              </li>
              <li>
                Inclure les informations nécessaires pour que nous puissions
                vous contacter (en particulier votre adresse postale, votre
                numéro de téléphone et votre adresse e-mail) ;
              </li>
              <li>
                Inclure une déclaration indiquant que vous considérez, de bonne
                foi, que l'utilisation du matériel protégé par des droits
                d'auteur ou d'autres droits de propriété intellectuelle n'est
                pas autorisée par le propriétaire, l'agent du propriétaire ou la
                loi ;
              </li>
              <li>
                Inclure une déclaration indiquant que les informations contenues
                dans la notification écrite sont exactes ; et
              </li>
              <li>
                Inclure une déclaration sous peine de parjure, indiquant que
                vous êtes autorisé à agir au nom du titulaire des droits
                d'auteur ou de tout autre droit de propriété intellectuelle.
              </li>
            </ul>
            Si la notification ne faisait pas référence à une violation de
            droits d'auteur ou d'autres droits de propriété intellectuelle,
            l'agent du DMCA d’Ironhack ne serait pas en mesure de traiter la
            question mentionnée.
          </Text3>
          <Text3 as="p">Envoi d'une contre-notification du DMCA</Text3>
          <Text3 as="p">
            Si nous supprimons ou limitons l'accès au matériel protégé par les
            droits d'auteur que vous nous avez mentionnés, nous vous indiquerons
            si ce retrait est assujetti à la réception valide d'une notification
            de retrait du Digital Millennium Copyright Act (DMCA,
            loi du millénaire sur le droit d'auteur numérique). En réponse, vous
            pouvez fournir à l'agent du DMCA d'Ironhack une contre-notification
            écrite contenant les informations suivantes :
          </Text3>
          <Text3 as="p">
            <ol>
              <li>Votre signature physique ou électronique ;</li>
              <li>
                L'identification du matériel retiré ou dont l'accès a été
                limité, et l'endroit où le matériel apparaissait avant qu’il
                soit retiré ou que son accès soit limité ;
              </li>
              <li>
                Une déclaration sous peine de parjure indiquant que vous
                considérez, de bonne foi, que le matériel a été retiré ou que
                son accès a été limité suite à une erreur ou à une défaillance
                dans l’identification du matériel à retirer ou dont l’accès doit
                être limité ;
              </li>
              <li>
                Votre nom, votre adresse postale et votre numéro de téléphone,
                ainsi qu'une déclaration selon laquelle vous acceptez la
                juridiction d'un tribunal fédéral de district correspondant à
                votre adresse postale ou, si votre adresse postale est située en
                dehors des États-Unis, de tout district judiciaire où nous
                pourrions être situés, et que vous accepterez la convocation
                liée à la plainte de la personne ayant envoyé la notification
                sur le matériel qui ferait l’objet d’une infraction ou de son
                agent.
              </li>
            </ol>
            Suspension des récidivistes
          </Text3>
          <Text3 as="p">
            Nous nous réservons le droit, à notre seule discrétion, de suspendre
            un Bootcamp Ironhack ou l'accès à ce site web ou service à tout
            utilisateur ayant fait l'objet de notifications répétées du DMCA ou
            ayant commis d’autres infractions. Les notifications et
            contre-notifications doivent répondre aux exigences légales en
            vigueur imposées par le DMCA ; pour plus d'informations voir
            https://www.copyright.gov/. Avant de présenter une notification ou
            une contre-notification, veuillez consulter votre conseiller
            juridique ainsi que la section 512 du titre 17 du Code des
            États-Unis, étant donné que le DMCA peut infliger des sanctions en
            cas de fausse déclaration.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">LIBÉRATION DES GARANTIES</Text2>
          <Text3 as="p">
            CE SITE WEB EST FOURNI « TEL QUEL » ET « AVEC TOUTES LES ERREURS »
            QU'IL PEUT CONTENIR ET VOUS ASSUMEZ TOUS LES RISQUES LIÉS À SA
            QUALITÉ ET À SES PERFORMANCES.
          </Text3>
          <Text3 as="p">
            NOUS NE GARANTISSONS PAS QUE LE SITE WEB, SON CONTENU, SES SERVICES
            NI SES FONCTIONS SOIENT OFFERTS DE MANIÈRE ININTERROMPUE ET
            OPPORTUNE, EN TOUTE SÉCURITÉ, SANS ERREUR NI QUE LES DÉFAUTS SERONT
            CORRIGÉS.
          </Text3>
          <Text3 as="p">
            LE SITE WEB ET SON CONTENU SONT FOURNIS « TELS QUELS » ET « SELON
            DISPONIBILITÉ ».
          </Text3>
          <Text3 as="p">
            NOUS NE POUVONS PAS GARANTIR QUE LES FICHIERS OU DONNÉES QUE VOUS
            TÉLÉCHARGEZ SUR CE SITE NE CONTIENNENT AUCUN VIRUS, CONTAMINATION,
            NI ÉLÉMENT DESTRUCTEUR.
          </Text3>
          <Text3 as="p">
            NOUS NE DONNONS AUCUNE GARANTIE DE QUELQUE NATURE QUE CE SOIT,
            EXPRESSE, IMPLICITE OU LÉGALE, NOTAMMENT LES GARANTIES DE PRÉCISION
            LIÉES À CE SITE WEB, Y COMPRIS, MAIS SANS S'Y LIMITER, LES GARANTIES
            IMPLICITES DE COMMERCIABILITÉ, D'ADAPTATION À UNE FIN OU À UN USAGE
            PARTICULIER, DE PROPRIÉTÉ ET DE NON-VIOLATION DES DROITS DE
            PROPRIÉTÉ INTELLECTUELLE.
          </Text3>
          <Text3 as="p">
            NOUS DÉCLINONS ÉGALEMENT TOUTE RESPONSABILITÉ POUR LES ACTIONS, LES
            OMISSIONS ET LA CONDUITE DE TIERS CONCERNANT OU LIÉS À VOTRE
            UTILISATION DU SITE WEB OU DES SERVICES D’IRONHACK.
          </Text3>
          <Text3 as="p">
            CELA SIGNIFIE QUE NOUS NE POUVONS PAS GARANTIR QUE LE SITE WEB
            FONCTIONNE SANS PROBLÈME.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">RÉSULTATS</Text2>
          <Text3 as="p">
            Nous ne donnons aucune garantie quant aux résultats qui peuvent être
            obtenus à partir de l'utilisation de ce site web ou concernant
            l'exactitude ou la fiabilité des informations obtenues via ce site
            web. Aucun conseil ou information, fourni oralement ou par écrit,
            que vous auriez obtenu via ce site web ou de nous-même, de nos
            filiales ou d'autres sociétés associées n’implique de garantie. Nous
            n'assumons aucun dédommagement équitable de quelque nature que ce
            soit.
          </Text3>
          <Text3 as="p">
            Nous mettons en œuvre tous nos efforts pour faire appel aux
            instructeurs et au personnel les plus performants afin de vous
            offrir la meilleure expérience Bootcamp Ironhack possible. Nous
            pouvons présenter un instructeur spécifique du Bootcamp Ironhack à
            des fins publicitaires, mais ceci ne constitue pas une
            recommandation de la part d’un tel instructeur ou une garantie que
            le cours auquel vous vous inscrivez sera dirigé par cet instructeur.
            Nous ne faisons aucune déclaration sur la qualité du travail ou la
            nature des instructeurs du Bootcamp Ironhack.
          </Text3>
          <Text3 as="p">
            Nous pouvons, de temps à autre, faire de la publicité ou promouvoir
            les résultats de certains étudiants du Bootcamp Ironhack sur ce site
            web, sur les réseaux sociaux ou dans des centres de promotion.
            Ironhack ne garantit aucun résultat, travail, salaire ou débouché
            professionnel. Le Bootcamp Ironhack n'est pas un programme
            d'insertion professionnelle et certains résultats ne pourront pas
            être reproduits Nous nous efforçons d'être absolument transparents
            lorsque nous présentons des témoignages d’étudiants d'Ironhack et
            des résultats antérieurs, y compris les notes moyennes et les
            exceptions. Notre but est de fournir aux participants du Bootcamp
            Ironhack les informations nécessaires pour comprendre que les
            résultats antérieurs ne constituent pas des indicateurs des
            résultats à venir.
          </Text3>
          <Text3 as="p">
            LE FAIT QU'UN ÉTUDIANT D’IRONHACK AIT OBTENU UN BON TRAVAIL À
            L’ISSUE DU BOOTCAMP IRONHACK NE GARANTIT PAS QUE VOUS OBTENIEZ LE
            MÊME RÉSULTAT. LES RÉSULTATS SONT EXTRÊMEMENT VARIABLES SELON
            L'EMPLACEMENT ET DÉPENDENT DES CONDITIONS DU MARCHÉ, INDÉPENDANTES
            DE NOTRE VOLONTÉ. NOUS NE GARANTISSONS AUCUN RÉSULTAT.
          </Text3>
          <Text3 as="p">
            Réaliser un Bootcamp Ironhack n’implique pas que vous deviendrez un
            expert dans la matière enseignée. Nos instructeurs et notre
            personnel s'efforcent d’encadrer l'apprentissage dans le Bootcamp
            Ironhack, mais certains étudiants n’achèvent pas le cours de façon
            satisfaisante. Bien qu’ils sortent diplômés du Bootcamp Ironhack,
            certains étudiants ne trouvent pas d'emploi dans leur domaine par la
            suite. Certains étudiants n'obtiennent pas le diplôme et d'autres
            n'acquièrent pas les compétences et les connaissances de la matière
            enseignée dans le Bootcamp Ironhack. Avant de vous inscrire à un
            Bootcamp Ironhack, il est conseillé de contacter un responsable de
            programme d’Ironhack pour évoquer les différents lieux et dates
            limites, les conditions d'admission, les droits et dépenses liés au
            cours ainsi que vos propres capacités.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">LIMITATION DE RESPONSABILITÉ</Text2>
          <Text3 as="p">
            NOUS NE SAURIONS ÊTRE TENUS RESPONSABLES DES DOMMAGES RÉSULTANT DE
            LA VISUALISATION, DE LA COPIE OU DU TÉLÉCHARGEMENT DE MATÉRIELS DE
            CE SITE WEB DANS LA MESURE OÙ LA LÉGISLATION APPLICABLE L’AUTORISE.
            EN AUCUN CAS NOUS NE SAURIONS ÊTRE TENUS RESPONSABLES DES DOMMAGES
            INDIRECTS, EXTRAORDINAIRES, EXEMPLAIRES, PUNITIFS, SPÉCIAUX,
            ACCIDENTELS OU CONSÉQUENTS (Y COMPRIS LA PERTE DE DONNÉES, DE
            REVENUS, DE BÉNÉFICES, D'UTILISATIONS OU AUTRES AVANTAGES
            ÉCONOMIQUES), QUELLE QUE SOIT LA MANIÈRE DONT ILS SE SERAIENT
            PRODUITS, ET MÊME SI NOUS SAVONS QUE DE TELS DOMMAGES SONT
            POSSIBLES.
          </Text3>
          <Text3 as="p">
            Certaines juridictions n'autorisent pas de limitations de
            responsabilité, auquel cas elles ne seraient pas applicables.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">DÉDOMMAGEMENT</Text2>
          <Text3 as="p">
            Vous acceptez d'indemniser et de décharger Ironhack, ses dirigeants,
            administrateurs, actionnaires, employés, agents, filiales ou
            associés de toute faute et tous les frais vis-à-vis de toute
            réclamation, plainte, perte, responsabilité ou dépense (y compris
            les honoraires d'avocat) imposée par des tiers à la suite ou en
            relation avec l'utilisation de ce site web.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">LÉGISLATION LOCALE CONTRLE DES EXPORTATIONS</Text2>
          <Text3 as="p">
            Nous contrôlons et gérons ce site web depuis notre siège social aux
            États-Unis d'Amérique, et certaines parties de ce site web peuvent
            ne pas être adaptées ou disponibles ailleurs. Si vous utilisez ce
            site en dehors des États-Unis d'Amérique, il relève de votre
            responsabilité de vous conformer aux lois applicables.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">
            RÈGLEMENT DES LITIGES ET ARBITRAGES RENONCIATION AU RECOURS
            COLLECTIF
          </Text2>
          <Text3 as="p">
            Veuillez lire attentivement cette disposition. Elle concerne vos
            droits légaux.
          </Text3>
          <Text3 as="p">
            Cette disposition sur le règlement des litiges et l'arbitrage, la
            renonciation au recours collectif (cette « 
            <strong>disposition</strong> ») facilite la résolution rapide et
            efficace de tout litige pouvant survenir entre vous et nous, par
            exemple, des plaintes ou des différends, qu’ils soient fondés sur
            des contrats, des lois, des législations, des décrets, des délits (y
            compris, sans s’y limiter, la fraude, les distorsions, les avantages
            frauduleux ou les négligences), ou toute autre théorie conforme au
            droit ou à l’équité, notamment la validité, l'exigibilité ou la
            portée de cette disposition (à l'exception du caractère applicable
            de la clause suivante relative à la renonciation au recours
            collectif). Par conséquent, le terme « litige » doit être interprété
            dans le sens aussi large que possible conformément à la législation
            et comprend toute réclamation contre d'autres parties relatives aux
            services ou produits qui vous sont proposés ou facturés (tels que
            nos preneurs de licence, fournisseurs, distributeurs ou vendeurs
            tiers), à condition que vous portiez plainte contre nous dans la
            même procédure.
          </Text3>
          <Text3 as="p">
            Cette disposition prévoit que tout litige entre vous et nous doit
            être réglé par un arbitrage exécutoire, car l'acceptation des
            présentes conditions constitue une renonciation à votre droit à une
            plainte et à l’opportunité d'être entendu par un juge ou un jury.
            Nous préférons procéder ainsi car nous considérons que l'arbitrage
            est plus efficace et moins controversé que le contentieux. Pour
            éviter tout doute, dans l’arbitrage n’intervient aucun juge ou jury,
            et le réexamen par un tribunal est limité. L'arbitre doit être guidé
            par le présent accord et a le pouvoir de déterminer les mêmes
            dommages-intérêts et solutions qu'un tribunal (y compris les
            honoraires d'avocat). Toutefois, vous pouvez renoncer à cette
            disposition, ce qui vous donnerait le droit ou l'opportunité
            d'intenter une action en justice auprès d’un tribunal, devant un
            juge ou un jury, ou de participer ou d'être représenté dans une
            affaire portée devant un tribunal par d'autres personnes (y compris,
            sans s'y limiter, les recours collectifs).
          </Text3>
          <Text3 as="p">
            VOUS ET NOUS CONVENONS QUE, SOUS RÉSERVE DES DISPOSITIONS CI-APRÈS,
            TOUT LITIGE, TEL QUE DÉFINI CI-DESSUS, QU’IL SOIT ACTUEL OU FONDÉ
            SUR DES ACTES OU OMISSIONS PASSÉS OU FUTURS, SERA RÉGLÉ,
            EXCLUSIVEMENT ET DÉFINITIVEMENT, PAR UN ARBITRAGE EXÉCUTOIRE PLUTÔT
            QUE DEVANT DES TRIBUNAUX, CONFORMÉMENT À LA PRÉSENTE DISPOSITION.
          </Text3>
          <Text3 as="p">Règlement d'une réclamation avant l’arbitrage</Text3>
          <Text3 as="p">
            Qu'ils soient portés devant les tribunaux ou réglés via un
            arbitrage, vous devez tout d'abord nous donner l'opportunité de
            résoudre les litiges. La première marche à suivre consiste à nous
            envoyer un courriel à l’adresse&nbsp;
            <a href="mailto:legal@ironhack.com">legal@ironhack.com</a>
            &nbsp; contenant les informations suivantes : (1) votre nom, (2)
            votre adresse, (3) une description écrite de la réclamation et le
            fondement du litige, et (4) une description de la solution précise
            que vous attendez. Si nous ne réglons pas le litige dans un délai de
            quarante-cinq (45) jours à compter de la réception de votre
            notification, vous pouvez alors soumettre le litige à l'arbitrage.
            Vous ne pouvez porter le litige devant un tribunal que dans les
            circonstances décrites ci-dessous.
          </Text3>
          <Text3 as="p">
            Exclusions de l'arbitrage / Droit à l'auto-exclusion
          </Text3>
          <Text3 as="p">
            Nonobstant ce qui précède, vous ou nous pouvons décider de porter un
            litige devant les tribunaux et de ne pas le régler via un arbitrage
            si : (a) le différend remplit les conditions pour être présenté
            devant un tribunal d’arbitrage dédié aux petits litiges ; ou (b)
            VOUS RENONCEZ AUX PROCÉDURES D'ARBITRAGE DANS UN DÉLAI DE 30 JOURS À
            COMPTER DE LA DATE À LAQUELLE VOUS ACCEPTEZ CET ACCORD (le « 
            <strong>délai d'auto-exclusion</strong> »). Vous pouvez renoncer à
            cette disposition d'arbitrage en nous envoyant un courriel à
            l’adresse&nbsp;
            <a href="mailto: legal@ironhack.com">legal@ironhack.com</a>&nbsp;
            contenant les informations suivantes : 1) votre nom, 2) votre
            adresse, 3) une déclaration claire que vous ne souhaitez pas régler
            les litiges éventuels vis-à-vis de nous via un arbitrage. Dans tous
            les cas, nous respecterons votre décision.
          </Text3>
          <Text3 as="p">
            De fait, nous nous engageons à ce que la décision de vous
            auto-exclure de cette disposition d'arbitrage n'ait aucun effet
            négatif sur notre relation. Mais nous devons appliquer le délai
            d’auto-exclusion,&nbsp;
            <strong>
              vous devez donc garder à l’esprit que toute demande
              d’auto-exclusion reçue une fois ce délai écoulé ne sera pas valide
              et que vous devrez régler tout litige via un arbitrage ou devant
              un tribunal
            </strong>
            &nbsp;
            <strong>dédié aux petits litiges.</strong>
          </Text3>
          <Text3 as="p">Procédures d'arbitrage</Text3>
          <Text3 as="p">
            Si cette disposition s'applique et que le litige n'est pas réglé tel
            qu’indiqué précédemment (règlement d'une réclamation avant
            l’arbitrage), vous et nous pouvons entamer une procédure
            d'arbitrage. L'Association américaine d’arbitrage (American
            Arbitration Association « AAA »), www.adr.org, ou le JAMS,
            www.jamsadr.com, se chargera de l'arbitrage de tous les litiges,
            avec un seul arbitre. L'arbitrage doit être entamé individuellement
            et jamais en tant qu'arbitrage collectif ou en tant que plainte ou
            arbitrage représentatif ou consolidé. L'arbitre doit rendre des
            décisions sur toutes les questions, y compris la portée de la
            présente disposition d'arbitrage.
          </Text3>
          <Text3 as="p">
            Dans le cas des arbitrages effectués par l’AAA, lorsque le litige ne
            dépasse pas 75 000 $, les Supplementary Procedures for
            Consumer-Related Disputes de l'AAA (procédures supplémentaires
            concernant les litiges de consommation) sont appliquées ; lorsque le
            litige dépasse 75 000 $, les Commercial Arbitration Rules (règles
            d'arbitrage commercial) de l'AAA sont appliquées. Dans les deux cas,
            les Optional Rules For Emergency Measures Of Protection (règles
            facultatives pour les mesures de protection d'urgence) de l'AAA sont
            appliquées. Les règlements de l'AAA sont disponibles sur www.adr.org
            ou au numéro de téléphone 1-800-778-7879. Pour les arbitrages
            effectués par le JAMS, les Comprehensive Arbitration Rules &amp;
            Procedures (les règles et procédures d'arbitrage global) et les
            Recommended Arbitration Discovery Protocols For Domestic, Commercial
            Cases (protocoles d'arbitrage recommandés pour les affaires
            domestiques et commerciales) sont appliqués. Le règlement de JAMS
            est disponible sur www.jamsadr.com ou au numéro de téléphone
            1-800-352-5267. Cette disposition prévaudra en cas de conflit avec
            le règlement d'arbitrage applicable. En aucun cas, les règlements ou
            procédures représentatifs ou de recours collectif ne s'appliquent à
            l'arbitrage.
          </Text3>
          <Text3 as="p">
            Étant donné que ce site web et ces conditions s'appliquent au
            commerce interétatique, la loi fédérale sur l'arbitrage (Federal
            Arbitration Act, « <strong>FAA »</strong>) régit l'arbitrage de tous
            les litiges. Toutefois, l'arbitre applique le droit matériel
            applicable conforme à la FAA et aux conditions prescriptives ou à la
            condition suspensive selon ses propres considérations.
          </Text3>
          <Text3 as="p">
            <em>Décision arbitrale</em> – L'arbitre peut attribuer à titre
            individuel tout dédommagement en vertu de la loi applicable et n'a
            pas le pouvoir d'accorder de dédommagement à, contre ou au bénéfice
            d’une personne qui ne prendrait pas part à la procédure. L'arbitre
            rend sa décision par écrit, mais il n'est pas tenu d'en exposer les
            motifs, à moins qu'une partie ne le lui demande ou que la loi
            applicable ne l'exige. La décision est définitive, finale et
            exécutoire pour les parties, à l'exception des droits d'appel prévus
            par la FAA ou toute autre loi applicable, et peut être rendue par
            tout tribunal ayant compétence sur les parties aux fins d'exécution.
          </Text3>
          <Text3 as="p">
            <em>Lieu de l'arbitrage </em>– Vous ou nous pouvons entamer un
            arbitrage en Floride et dans le district judiciaire fédéral
            correspondant à votre adresse de facturation.
          </Text3>
          <Text3 as="p">
            <em>Paiement des frais et dépens d'arbitrage</em> – À condition que
            vous en ayez fait la demande par écrit avant l'arbitrage, nous
            règlerons tous les frais pour que l'arbitrage soit entamé ainsi que
            les frais pour les audiences de l'AAA ou du JAMS, les honoraires,
            frais et dépenses de l'arbitre si vous en avez fait la demande par
            écrit auprès de l’arbitre correspondant avant ou pendant la première
            audition relative à l'arbitrage. Cependant, vous êtes responsable de
            tous les frais et coûts supplémentaires que vous engagez pendant
            l'arbitrage, y compris, mais sans s'y limiter, les honoraires
            d'avocats ou d'experts. En plus des frais et coûts recouvrables en
            vertu de la législation applicable, si vous nous prévenez et
            négociez de bonne foi avec nous tel qu’indiqué à la section
            ci-dessus intitulée « Règlement d'une réclamation avant
            l’arbitrage » et que l'arbitre détermine que vous êtes la partie
            gagnante, vous aurez droit au recouvrement des honoraires et des
            frais raisonnables des avocats tels que déterminé par l'arbitre.
          </Text3>
          <Text3 as="p">Renonciation au recours collectif</Text3>
          <Text3 as="p">
            À moins que le contraire ne soit prévu dans la présente disposition
            d'arbitrage, l'arbitre ne peut regrouper les réclamations de plus
            d'une personne et ne peut présider toute autre forme de recours ou
            procédure collectif ou représentatif (comme un recours collectif, un
            recours consolidé, un recours représentatif ou une action privée) à
            moins que vous et nous n’en convenions expressément par écrit après
            le début de l'arbitrage.
          </Text3>
          <Text3 as="p">
            Si vous décidez de porter le litige devant les tribunaux après vous
            être auto-exclu de cette disposition d'arbitrage, tel qu’il est
            précisé ci-dessus, la présente renonciation au recours collectif ne
            s'appliquera pas à vous. Ni vous ni les autres utilisateurs de ce
            site web ne pouvez être des représentants d'un groupe, des membres
            d'un groupe ou participer sous quelque forme que ce soit à une
            procédure collective, consolidée ou représentative sans respecter
            les exigences d’auto-exclusion précisées précédemment.
          </Text3>
          <Text3 as="p">Renonciation à un jury</Text3>
          <Text3 as="p">
            Vous comprenez et acceptez qu'en acceptant la disposition
            d'arbitrage des présentes conditions, vous et nous renonçons au
            droit à un procès avec un jury ou à un procès devant un juge dans un
            tribunal public. Sans cette disposition d'arbitrage, vous et nous
            pourrions avoir le droit ou l'opportunité de porter des litiges
            devant un tribunal, un juge ou un jury, ou de participer ou d'être
            représentés dans une affaire portée devant un tribunal par d'autres
            (y compris des recours collectifs). Sauf indication contraire
            ci-après, ces droits sont nuls. D'autres droits dont vous auriez
            bénéficié si vous aviez porté l’affaire devant un tribunal (par
            exemple, les droits d'appel et certains types de révélations)
            peuvent être limités ou également nuls.
          </Text3>
          <Text3 as="p">Divisibilité</Text3>
          <Text3 as="p">
            Dans le cas où l’une des clauses de cette disposition d'arbitrage
            (autre que la clause de renonciation au recours collectif) serait
            jugée illégale ou inapplicable, cette clause serait supprimée de la
            présente disposition d'arbitrage, les autres clauses demeurant
            pleinement en vigueur. Si la clause de renonciation au recours
            collectif était jugée illégale ou inapplicable, l'ensemble de la
            disposition d'arbitrage serait inapplicable et le litige serait
            résolu par un tribunal.
          </Text3>
          <Text3 as="p">Continuité</Text3>
          <Text3 as="p">
            Cette disposition d'arbitrage restera en vigueur après la fin de
            votre Bootcamp Ironhack avec nous ou nos filiales et une fois que
            vous cesserez d’utiliser ce site web. Nonobstant toute disposition
            contraire dans les présentes conditions, si nous modifions cette
            disposition d'arbitrage de quelque manière que ce soit (sauf en ce
            qui concerne les changements d'adresse pour les notifications), nous
            acceptons que vous rejetiez cette modification et nous demandiez de
            respecter cette disposition tel que rédigée dans le présent document
            en cas de litige.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">LANGUE</Text2>
          <Text3 as="p">
            Les parties concernées ont expressément exigé que le présent accord
            ainsi que tous les documents et notifications s'y rapportant soient
            rédigés en anglais.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">GÉNÉRAL</Text2>
          <Text3 as="p">
            Nous considérons que la plupart des problèmes peuvent être résolus
            directement par la communication (si nous avons l'impression que
            vous ne respectez pas ces conditions, nous vous le ferons savoir).
            Soucieux d’entretenir avec vous de bonnes relations, nous vous
            fournirons également des conseils sur les actions correctives
            nécessaires.
          </Text3>
          <Text3 as="p">
            Cependant, certaines violations de ces conditions, à notre seule
            discrétion, peuvent nécessiter la suspension immédiate de votre
            accès à ce site web sans préavis. Les présentes conditions sont
            régies par la loi fédérale sur l’arbitrage, la législation de l'État
            de Floride et la législation fédérale des États-Unis applicable,
            indépendamment du choix des dispositions légales ou du conflit de
            lois. Les lois étrangères ne s'appliquent pas aux présentes
            conditions. À l'exception des litiges soumis à l'arbitrage décrits
            ci-dessus, tout litige relatif aux présentes conditions ou à ce site
            web sera porté devant les tribunaux du comté de Miami-Dade, en
            Floride. Dans le cas où l’une des dispositions des présentes
            conditions serait considérée comme nulle, incompatible ou
            inapplicable par un tribunal compétent, elle serait limitée ou
            éliminée dans la mesure strictement nécessaire et remplacée par une
            disposition valide représentant au mieux les intentions des
            présentes conditions, afin que ces dernières demeurent pleinement en
            vigueur, et qu’aucune autre condition ne soit modifiée. Le fait de
            ne pas faire respecter ces conditions ne signifie pas que nous
            renonçons à nos droits. Les présentes conditions constituent
            l'intégralité de l'accord entre vous et nous et remplacent donc
            toute négociation, discussion ou accord antérieur ou actuel entre
            qui que ce soit concernant ce site web. Les droits de propriété
            intellectuelle, les renonciations de garantie, les déclarations de
            votre part, les indemnisations, les limitations de responsabilité et
            les dispositions générales resteront en vigueur à la résiliation des
            présentes conditions.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">NOTIFICATIONS AUX CONSOMMATEURS DE CALIFORNIE</Text2>
          <Text3 as="p">
            Conformément à la section 1789.3 du Code civil de Californie, les
            utilisateurs ont droit à la notification suivante sur les droits du
            consommateur. Le fournisseur de ce site web et de ce service est
            Ironhack, 990 Biscayne Blvd, Ste. 502, Miami FL 33132. Si vous avez
            des questions ou des réclamations concernant ce site ou service,
            vous pouvez nous contacter directement à l’adresse&nbsp;
            <a href="mailto: legal@ironhack.com">legal@ironhack.com</a>. Vous
            pouvez également nous contacter en écrivant à l’adresse Ironhack,
            990 Biscayne Blvd, Ste. 502, Miami FL 33132. Les résidents de
            Californie peuvent contacter l'Unité d'assistance aux consommateurs
            de la Division des services aux consommateurs du Département des
            questions de consommation de Californie par courrier postal à
            l’adresse 1625 North Market Blvd, Sacramento, CA 95834 ou par
            téléphone au (916) 445-1254 ou (800) 952-5210, pour les
            malentendants TDD (800) 326-2297 ou TDD (916) 322-1700.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">COMMENTAIRES</Text2>
          <Text3 as="p">
            Tout ce que vous nous envoyez (par exemple les commentaires,
            témoignages, citations, questions, suggestions, documents,
            collectivement les « <strong>Commentaires</strong> ») via toute
            communication de quelque nature que ce soit (par exemple des appels,
            commentaires sur le blog, courriels) a un caractère non confidentiel
            et une forme de propriété non exclusive. Sauf disposition légale
            contraire, vous cédez par les présentes tous les droits, titres et
            intérêts relatifs à toutes les idées, connaissances, concepts,
            techniques ou autres propriétés intellectuelles ou droits d'auteur
            inclus dans les commentaires, qu'ils soient brevetables ou non, et
            nous pouvons en faire usage librement sans aucune attribution ni
            contrepartie à votre égard, à toute fin, notamment pour le
            développement et la fabrication présente, passée ou à venir, les
            licences, la commercialisation et la vente, directement ou
            indirectement, des produits et services, en utilisant lesdits
            commentaires. Pour le cas où la cession susmentionnée serait
            interdite par la loi, vous nous accordez par les présentes une
            licence exclusive, transférable, internationale, sans charges et
            entièrement payée (y compris le droit de sous-licence) pour utiliser
            et exploiter tous les commentaires selon nos critères, à notre seule
            discrétion. Nonobstant ce qui précède, vous comprenez et acceptez
            que nous n'avons aucune obligation d'utiliser, de publier, de
            reproduire ou de distribuer lesdites idées, connaissances, concepts
            ou techniques incluses dans les commentaires, et vous n'avez aucun
            droit d'exiger une telle utilisation, publication, reproduction ni
            distribution.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">CONTACT</Text2>
          <Text3 as="p">
            Pour toute question au sujet de ces conditions ou en cas de besoin,
            veuillez nous contacter à l’adresse&nbsp;
            <a href="mailto: legal@ironhack.com">legal@ironhack.com</a>.
          </Text3>
        </Question>
      </Layout>
      <Footer page={pageName} />
    </>
  );
};

export const query = graphql`
  query {
    locales: allLocale(
      filter: {
        lng: { eq: "fr" }
        ns: { regex: "/(seo)|(breadcrumbs)|(menu)|(banner)/" }
      }
    ) {
      ...TranslationFragment
    }
  }
`;

export default withI18next('fr')(TermOfUse);
